import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PersonReport } from '../models/personReport';
import { JiraUser } from '../models/jiraUser';
import { DocExport } from '../models/docExport';
import { BaseApiService } from './baseApiService';
import { ScoroProject } from '../models/scoroProject';

@Injectable({
  providedIn: 'root'
})
export class PersonReportApiService extends BaseApiService {

  public getPersonReport(jiraUsers?: JiraUser[], dateFrom?: Date, dateTo?: Date):Observable<PersonReport> {
    const params = this.httpParams(jiraUsers, null, null, null, dateFrom, dateTo);
    return this.httpClient.get<PersonReport>(this.config.apiBaseUrl + '/api/personreport', {params});
  }

  public getExport(
    jiraUsers: JiraUser[], 
    scoroProjects: ScoroProject[], 
    scoroClient: number, 
    billingType: string, 
    dateFrom: Date, 
    dateTo: Date,
    emailAddress: string):Observable<DocExport> {
      let params = this.httpParams(jiraUsers, scoroProjects, scoroClient, billingType, dateFrom, dateTo);
      params = params.set('emailAddress', emailAddress);
      return this.httpClient.get<DocExport>(this.config.apiBaseUrl + '/api/personreport/export', {params});
  }
}