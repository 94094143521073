import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { TimeReport } from '../models/timeReport';
import { formatDate } from '@angular/common';
import { JiraUser } from '../models/jiraUser';
import { ScoroProject } from '../models/scoroProject';
import { ChangeWorklog } from '../models/changeWorklog';
import { BaseApiService } from './baseApiService';
import { DocExport } from '../models/docExport';
import { ExportApi } from '../interfaces/exportApi.interface';
import { ScoroClient } from '../models/scoroClient';

@Injectable({
  providedIn: 'root'
})
export class TimeReportService extends BaseApiService implements ExportApi {

  public getTimeReport(
    jiraUsers?: JiraUser[], 
    scoroProjects?: ScoroProject[], 
    scoroClient?: number, 
    billingType?: string, 
    dateFrom?: Date, 
    dateTo?: Date):Observable<TimeReport> {
    const params = this.httpParams(jiraUsers, scoroProjects, scoroClient, billingType, dateFrom, dateTo);
    return this.httpClient.get<TimeReport>(this.config.apiBaseUrl + '/api/timereport', {params});
  }

  public getExport(
    jiraUsers?: JiraUser[], 
    scoroProjects?: ScoroProject[], 
    scoroClient?: number, 
    billingType?: string, 
    dateFrom?: Date, 
    dateTo?: Date,
    emailAddress?: string
  ):Observable<DocExport> {
    let params = this.httpParams(jiraUsers, scoroProjects, scoroClient, billingType, dateFrom, dateTo);
    params = params.set('emailAddress', emailAddress);
    return this.httpClient.get<DocExport>(this.config.apiBaseUrl + '/api/timereport/export', {params});
  }

  public getExportCSVUrl(
    jiraUsers?: JiraUser[], 
    scoroProjects?: ScoroProject[], 
    scoroClient?: number, 
    billingType?: string, 
    dateFrom?: Date, 
    dateTo?: Date
  ) {
    let params = this.httpParams(jiraUsers, scoroProjects, scoroClient, billingType, dateFrom, dateTo);
    return this.config.apiBaseUrl + '/api/timereport/exportcsv?' + params.toString();
  }

  public changeWorklog(changeWorklog: ChangeWorklog) {
    return this.httpClient.put(this.config.apiBaseUrl + '/api/timereport', changeWorklog);
  }
}