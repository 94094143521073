import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JiraUser } from '../models/jiraUser';
import { Observable } from 'rxjs';
import { AppConfigService } from './appConfig.service';

@Injectable({
  providedIn: 'root'
})
export class JiraService {

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) { }

  public getJiraUsers():Observable<JiraUser[]> {
    return this.httpClient.get<JiraUser[]>(this.config.apiBaseUrl + '/api/jira/users');
  }
}
