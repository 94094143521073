<section class="section">
    <app-person-report-filters></app-person-report-filters>
    <hr>
    <div class="panel">
        <p class="panel-heading">Person Report
            <span class="is-pulled-right has-text-info is-size-6 is-clickable expand-all" *ngIf="hasLoaded" (click)="expandAll()">
                <span *ngIf="!hasExpanded">Expand All</span>
                <span *ngIf="hasExpanded">Collapse All</span>
            </span>
        </p>
        <div class="panel-block">
            <div class="content" *ngIf="!hasLoaded">
                <nwb-spinner [color]="'#bbbbbb'"></nwb-spinner>
            </div>
            <table class="table is-fullwidth is-hoverable" *ngIf="hasLoaded">
                <thead>
                    <tr>
                        <th>User Name</th>
                        <th>Hours Logged</th>
                        <th>Hours Expected</th>
                        <th>Unaccounted Hours</th>
                        <th>Internal Issues</th>
                        <th>Mentor Projects</th>
                        <th>Client Work</th>
                        <th>Billable Client Work</th>
                        <th>Non-Billable Client Work</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let person of personReport.persons">
                        <tr class="is-clickable" (click)="selectPerson(person)" [class.is-selected]="selectedPersons.indexOf(person.username) >= 0">
                            <td>
                                <strong>{{findDisplayName(person.username)}}</strong>
                            </td>
                            <td>{{person.hoursLogged | number:'1.2-2'}}</td>
                            <td>{{person.hoursExpected | number: '1.2-2'}}</td>
                            <td>{{person.unaccountedHours | number: '1.2-2'}}</td>
                            <td>{{person.internalIssues | number:'1.2-2'}}</td>
                            <td>{{person.mentorProjects | number: '1.2-2'}}</td>
                            <td>{{person.clientIssues | number: '1.2-2'}}</td>
                            <td>{{person.billable | number: '1.2-2'}}</td>
                            <td>{{person.nonBillable | number: '1.2-2'}}</td>
                        </tr>
                        <tr *ngFor="let projectLine of person.projectLines; let projectIndex = index" [class.is-hidden]="selectedPersons.indexOf(person.username) === -1">
                            <td>{{projectLine.scoroProjectName}}</td>
                            <td>{{projectLine.hoursLogged | number: '1.2-2'}}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{{projectLine.internalIssues | number: '1.2-2'}}</td>
                            <td>{{projectLine.mentorProjects | number: '1.2-2'}}</td>
                            <td>{{projectLine.clientIssues | number: '1.2-2'}}</td>
                            <td>{{projectLine.billable | number: '1.2-2'}}</td>
                            <td>{{projectLine.nonBillable | number: '1.2-2'}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</section>
<app-export-modal [(exportableFiltersService)]="personReportFiltersService" [(exportApiService)]="personReportApiService"></app-export-modal>