import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { JiraUser } from '../core/models/jiraUser';
import { ExportFilters } from '../core/models/exportFilters';
import { ExportableFiltersService } from '../core/interfaces/exportableFiltersService.interface';

@Injectable({ providedIn: 'root' })
export class PersonReportFiltersService implements ExportableFiltersService {
    private subject = new Subject<any>();
    private jiraUsersSubject = new Subject<any>();
    private exportSubject = new Subject<ExportFilters>();
    
    sendFilters(personReportFilters: any) {
        this.subject.next(personReportFilters);
    }

    sendJiraUsers(jiraUsers: Array<JiraUser>) {
        this.jiraUsersSubject.next(jiraUsers);
    }

    getFilters(): Observable<any> {
        return this.subject.asObservable();
    }

    getJiraUsers(): Observable<any> {
        return this.jiraUsersSubject.asObservable();
    }

    sendExportFilters(filters: ExportFilters) {
        this.exportSubject.next(filters);
    }

    getExportFilters():Observable<ExportFilters> {
        return this.exportSubject.asObservable();
    }
}