import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from './appConfig.service';
import { formatDate } from '@angular/common';
import { ScoroProject } from '../models/scoroProject';
import { JiraUser } from '../models/jiraUser';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseApiService {

  constructor(
    protected httpClient: HttpClient,
    protected config: AppConfigService
  ) { }

  protected httpParams(jiraUsers: JiraUser[], scoroProjects: ScoroProject[], scoroClient: number, billingType: string, dateFrom?: Date, dateTo?: Date): HttpParams {
    let params = new HttpParams();

    if(jiraUsers !== undefined && jiraUsers !== null && jiraUsers.length > 0) {
       params = params.set('jiraUsers', jiraUsers.map(u => u.name).join(','));
    }
    
    if(scoroProjects !== undefined && scoroProjects !== null && scoroProjects.length > 0) {
      params = params.set('scoroProjects', scoroProjects.map(p => p.value).join(','));
    }
    
    if(scoroClient !== undefined && scoroClient !== null && scoroClient > 0) {
      params = params.set('scoroClient', scoroClient.toString());
    }
    
    if(billingType !== undefined && billingType !== null && billingType.length > 0) {
      params = params.set('billingType', billingType);
    }
    
    if(dateFrom !== undefined && dateFrom !== null) {
      params = params.set('dateFrom', formatDate(dateFrom, 'yyyy-MM-dd', 'en-GB'));
    }
    
    if(dateTo !== undefined && dateFrom !== null) {
      params = params.set('dateTo', formatDate(dateTo, 'yyyy-MM-dd', 'en-GB'));
    }

    return params;
  }

}
