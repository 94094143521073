import { Component, OnInit } from '@angular/core';
import { PersonReportLoadingService } from './person-report-loading.service';
import { PersonReportApiService } from '../core/services/personReportApi.service';
import { PersonReportFiltersService } from '../person-report-filters/person-report-filters.service';
import { PersonReport } from '../core/models/personReport';
import { JiraUser } from '../core/models/jiraUser';
import { Person } from '../core/models/person';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-person-report',
  templateUrl: './person-report.component.html',
  styleUrls: ['./person-report.component.scss']
})
export class PersonReportComponent implements OnInit {

  public hasLoaded: boolean = false;
  public personReport: PersonReport;
  public jiraUsers: JiraUser[];
  public selectedPersons = [];
  public hasExpanded: boolean;

  constructor(
    private personReportLoadingService: PersonReportLoadingService,
    public personReportFiltersService: PersonReportFiltersService,
    public personReportApiService: PersonReportApiService,
    private titleService: Title) {

      this.personReport = new PersonReport();
      this.personReport.persons = [];
      this.personReportLoadingService.sendLoading(true);
      this.titleService.setTitle('Person Report - MTRCP');

      this.personReportFiltersService.getJiraUsers().subscribe(jiraUsers => {
        this.jiraUsers = jiraUsers;
      });

      this.personReportFiltersService.getFilters().subscribe(filters => {
        this.personReportLoadingService.sendLoading(true);
        this.hasLoaded = false;
        this.personReportApiService.getPersonReport(filters.jiraUsers, filters.dateFrom, filters.dateTo).subscribe(personReport => {
          this.personReport = personReport;
          this.hasLoaded = true;
          this.personReportLoadingService.sendLoading(false);

          // If there is only one person we may as well expand it.. right?
          if(this.personReport.persons !== undefined && this.personReport.persons.length === 1) {
            this.expandAll();
          }
        });
      });

    }

  ngOnInit(): void {
    
  }

  expandAll(): void {
    if(this.hasExpanded) {
      this.selectedPersons = [];
      this.hasExpanded = false;
      return;
    }

    this.selectedPersons = [];
    this.personReport.persons.forEach((p) => {
      this.selectedPersons.push(p.username);
    });
    this.hasExpanded = true;
  }

  selectPerson(person: Person) {
    const existingIndex = this.selectedPersons.indexOf(person.username);
    if(existingIndex >= 0) {
      this.selectedPersons.splice(existingIndex, 1);
    } else {
      this.selectedPersons.push(person.username);
    }

    this.hasExpanded = this.selectedPersons.length > 0;
  }

  findDisplayName(username: string) {

    if(this.jiraUsers === undefined) {
      return '';
    }

    const userIndex = this.jiraUsers.findIndex((u) => u.name === username);
    if(userIndex >= 0) {
      return this.jiraUsers[userIndex].displayName;
    }
    return '';
  }

}
