<section class="section">
    <app-project-filters></app-project-filters>
    <hr>
    <div class="panel">
        <p class="panel-heading">Projects Report
            <span class="is-pulled-right has-text-info is-size-6 is-clickable expand-all" *ngIf="hasLoaded" (click)="expandAll()">
                <span *ngIf="!hasExpanded">Expand All</span>
                <span *ngIf="hasExpanded">Collapse All</span>
            </span>
        </p>
        <div class="panel-block" (click)="hideQuote()">
            <div class="content" *ngIf="!hasLoaded">
                <nwb-spinner [color]="'#bbbbbb'"></nwb-spinner>
            </div>
            <table class="table is-fullwidth is-hoverable" *ngIf="hasLoaded">
                <thead>
                    <tr>
                        <th>Project</th>
                        <th>Day Rate</th>
                        <th>Non-Bill Hrs</th>
                        <th>Non Bill &pound;</th>
                        <th>Bill Hrs</th>
                        <th>Bill &pound;</th>
                        <th>Total Hrs</th>
                        <th>Total &pound;</th>
                        <th>OOS Hrs</th>
                        <th>OOS &pound;</th>
                        <th>Scoro Total</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let project of projectReport.projects">
                        <tr class="is-clickable" (click)="selectProject(project)" [class.is-selected]="selectedProjects.indexOf(project.scoroProjectId) >= 0">
                            <td colspan="2">
                                <strong>{{project.scoroProjectTitle}}</strong>
                                <a class="is-pulled-right is-size-7" [routerLink]="['/home', getTimeReportParams(project)]">Time Report</a>
                            </td>
                            <td>{{project.projectTotalNonBillableSeconds | secondsToHours}}</td>
                            <td>{{project.projectTotalNonBillableSum | currency:'GBP'}}</td>
                            <td>{{project.projectTotalBillableSeconds | secondsToHours}}</td>
                            <td>{{project.projectTotalBillableSum | currency:'GBP'}}</td>
                            <td>{{project.projectTotalSeconds | secondsToHours}}</td>
                            <td>{{project.projectTotalSum | currency:'GBP'}}</td>
                            <td>{{project.projectTotalOosSeconds | secondsToHours }}</td>
                            <td>{{project.projectTotalOosSum | currency:'GBP'}}</td>
                            <td *ngIf="project.scoroQuotes.length > 0" (mouseenter)="showQuote(project)">
                                <div class="dropdown is-right" [class.is-active]="visibleQuote === project.scoroProjectId">
                                    <div class="dropdown-trigger">
                                        <span>{{project.scoroQuotesTotalSum | currency:'GBP'}}</span>
                                    </div>
                                    <div class="dropdown-menu" style="width: 250px;">
                                        <div class="dropdown-content">
                                            <div class="dropdown-item">
                                                <div class="content">
                                                    <ng-container *ngFor="let quote of project.scoroQuotes;let i = index;let isLast = last">
                                                        Quote: {{i + 1}} | {{quote.sum | currency:'GBP'}} | {{quote.confirmed_date |  date: 'd/MM/yy'}}<br>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="is-pulled-right">
                                    <fa-icon [icon]="faInfoCircle"></fa-icon>
                                </span>
                            </td>
                            <td *ngIf="project.scoroQuotes.length === 0">
                                {{project.scoroQuotesTotalSum | currency:'GBP'}}
                            </td>
                        </tr>
                        <tr *ngFor="let projectLine of filterByPositiveId(project); let projectIndex = index" [class.is-hidden]="selectedProjects.indexOf(project.scoroProjectId) === -1">
                            <td>{{projectLine.title}}</td>
                            <td>{{projectLine.hourlyRate | hourlyRateToDay | currency:'GBP'}}</td>
                            <td>{{projectLine.totalNonBillableSeconds | secondsToHours}}</td>
                            <td>{{projectLine.totalNonBillableSum | currency:'GBP'}}</td>
                            <td>{{projectLine.totalBillableSeconds | secondsToHours}}</td>
                            <td>{{projectLine.totalBillableSum | currency:'GBP'}}</td>
                            <td>{{projectLine.totalSeconds | secondsToHours}}</td>
                            <td>{{projectLine.totalSum | currency:'GBP'}}</td>
                            <td>{{projectLine.totalOosSeconds | secondsToHours}}</td>
                            <td colspan="2">{{projectLine.totalOosSum | currency:'GBP'}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</section>
<app-export-modal [(exportableFiltersService)]="projectFiltersService" [(exportApiService)]="projectReportApiService"></app-export-modal>