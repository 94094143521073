import { Component, OnInit } from '@angular/core';
import { TimeFiltersService } from '../time-filters/time-filters.service';
import { TimeReportService } from '../core/services/timeReport.service';
import { TimeReport } from '../core/models/timeReport';
import { Issue } from '../core/models/issue';
import { JiraUser } from '../core/models/jiraUser';
import { WorklogItem } from '../core/models/worklogItem';
import { Subject, Observable } from 'rxjs';
import { HomeService } from './home.service';
import { ChangeWorklog } from '../core/models/changeWorklog';
import { faExternalLinkAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { NwbSnackbarService, NwbSnackbarConfig } from '@wizishop/ng-wizi-bulma';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public hasLoaded: boolean;
  public timeReport: TimeReport;
  public jiraUsers: JiraUser[];
  private loadingSubject = new Subject<boolean>();
  public isShowSaveWorklog: boolean = false;

  // These are all used for the update worklog modal dialog
  public currentWorklogId: number = 0;
  public currentWorklogBillable: boolean = false;
  public currentWorklogComment: string = '';
  public currentIssueKey: string = '';
  public currentIssueSummary: string = '';
  public currentWorklogUsername: string = '';
  public hasExpanded: boolean = false;

  public isSavingWorklog: boolean = false;
  public worklogError: boolean = false;

  public selectedIssues = [];

  faExternalLinkAlt = faExternalLinkAlt;
  faEdit = faEdit;

  constructor(
    public timeReportService: TimeReportService,
    public timeFiltersService: TimeFiltersService,
    public homeService: HomeService,
    private nwbSnackbarService: NwbSnackbarService,
    private titleService: Title
  ) { 
    
    this.hasLoaded = false;
    this.timeReport = new TimeReport();
    this.timeReport.issueItems = [];
    this.timeReport.totalHours = 0;

    this.homeService.sendLoading(true);

    this.timeFiltersService.getJiraUsers().subscribe(jiraUsers => {
      this.jiraUsers = jiraUsers;
    });

    this.timeFiltersService.getFilters().subscribe(timeFilters => {
      this.hasLoaded = false;
      this.homeService.sendLoading(true);
      this.timeReportService.getTimeReport(
        timeFilters.jiraUsers,
        timeFilters.scoroProjects,
        timeFilters.scoroClient,
        timeFilters.billingAttribute,
        timeFilters.dateFrom,
        timeFilters.dateTo
      ).subscribe(result => {
        
        this.timeReport = result;
        this.hasLoaded = true;
        this.homeService.sendLoading(false);
      })
    });

    this.titleService.setTitle('Time Report - MTRCP');
  }

  selectIssue(issue: Issue) {
    const existingIndex = this.selectedIssues.indexOf(issue.issueKey);
    if(existingIndex >= 0) {
      this.selectedIssues.splice(existingIndex, 1);
    } else {
      this.selectedIssues.push(issue.issueKey);
    }

    this.hasExpanded = this.selectedIssues.length > 0;
  }

  // private getBillingAttribute(worklogItem: WorklogItem) {
  //   return worklogItem.notBillable;
  // }

  isBillable(worklogItem: WorklogItem) {
    return !worklogItem.notBillable;
  }

  isOOS(worklogItem: WorklogItem) {
    return worklogItem.notMentorIssue;
  }

  getBillingAttributeLabel(worklogItem: WorklogItem) {
    const nonBillableAttribute = worklogItem.notBillable;

    if (nonBillableAttribute === true) {
      return '<strong class="has-text-warning">Non billable work</strong>';
    }

    if (nonBillableAttribute === false) {
      return '<strong class="has-text-info">Billable work</b>';
    }

    return `<strong class="has-text-dark">${nonBillableAttribute}</strong>`;
  }

  findDisplayName(username: string) {

    if(this.jiraUsers === undefined) {
      return '';
    }

    const userIndex = this.jiraUsers.findIndex((u) => u.name === username);
    if(userIndex >= 0) {
      return this.jiraUsers[userIndex].displayName;
    }
    return '';
  }

  formatHours(num: number) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  getLoading():Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  showSaveWorklog(worklogItem: WorklogItem) {
    this.isShowSaveWorklog = true;
    this.currentWorklogId = worklogItem.worklogId;
    this.currentWorklogBillable = worklogItem.notBillable === false;
    this.currentWorklogComment = worklogItem.workDescription;
    this.currentIssueKey = worklogItem.issueKey;
    this.currentIssueSummary = worklogItem.issueSummary;
    this.currentWorklogUsername = this.findDisplayName(worklogItem.username);
  }

  hideSaveWorklog() {
    this.isShowSaveWorklog = false;
    this.isSavingWorklog = false;
    this.currentWorklogId = 0;
    this.currentWorklogBillable = false;
    this.currentWorklogComment = '';
    this.worklogError = false;
    this.currentIssueKey = '';
    this.currentIssueSummary = '';
    this.currentWorklogUsername = '';
  }

  saveWorklog() {
    this.isSavingWorklog = true;
    
    const changeWorklog = new ChangeWorklog();

    changeWorklog.notBillable = !this.currentWorklogBillable;
    changeWorklog.worklogId = this.currentWorklogId;
    changeWorklog.comment = this.currentWorklogComment;

    this.timeReportService.changeWorklog(changeWorklog).subscribe(r => {
      const matchingIssue = this.timeReport.issueItems.find(i => i.issueId === this.currentIssueKey);

      if(matchingIssue !== null) {
        const matchingWorklogItem = matchingIssue.worklogItems.find(x => x.worklogId === this.currentWorklogId);

        if(matchingWorklogItem !== null) {
          matchingWorklogItem.workDescription = this.currentWorklogComment;
          matchingWorklogItem.notBillable = !this.currentWorklogBillable;
        }
      }
      this.hideSaveWorklog();
    }, () => {
      this.worklogError = true;
      this.isSavingWorklog = false;
    });
  }

  changeBillable(worklogItem: WorklogItem) {
    //debugger;

    worklogItem.notBillable ? worklogItem.notBillable = false : worklogItem.notBillable = true;

    const changeWorklog = new ChangeWorklog();
   
    changeWorklog.notMentorIssue = worklogItem.notMentorIssue;
    changeWorklog.notBillable = worklogItem.notBillable;
    changeWorklog.worklogId = worklogItem.worklogId;

    this.timeReportService.changeWorklog(changeWorklog).subscribe(r => {
      this.nwbSnackbarService.open({
        message: worklogItem.issueKey.concat(' - Updated billable status'),
        duration: 2000,
        action: 'Close'
      });
    }, e => {
      this.nwbSnackbarService.open({
        message: worklogItem.issueKey.concat(' - Error updating billable status'),
        duration: 5000,
        action: 'Close'
      });
    });
  }

  changeOOS(worklogItem: WorklogItem) {
    
    worklogItem.notMentorIssue ? worklogItem.notMentorIssue = false : worklogItem.notMentorIssue = true;

    const changeWorklog = new ChangeWorklog();

    changeWorklog.notBillable = worklogItem.notBillable;
    changeWorklog.notMentorIssue = worklogItem.notMentorIssue;
    changeWorklog.worklogId = worklogItem.worklogId;

    this.timeReportService.changeWorklog(changeWorklog).subscribe(r => {
      this.nwbSnackbarService.open({
        message: worklogItem.issueKey.concat(' - Updated OOS'),
        duration: 2000,
        action: 'Close'
      });
    }, e => {
      this.nwbSnackbarService.open({
        message: worklogItem.issueKey.concat(' - Error updating OOS'),
        duration: 5000,
        action: 'Close'
      });
    });
  }

  commentKeydown(event: KeyboardEvent) {
    if(event.keyCode === 13) {
      event.preventDefault();
      return;
    }
  }

  commentKeyup(event: KeyboardEvent, comment: string, worklogItem: WorklogItem) {
    if(event.ctrlKey && event.keyCode === 13) {
      const changeWorklog = new ChangeWorklog();
    
      changeWorklog.notBillable = worklogItem.notBillable;
      changeWorklog.worklogId = worklogItem.worklogId;
      changeWorklog.comment = comment;

      this.timeReportService.changeWorklog(changeWorklog).subscribe(r => {
        this.nwbSnackbarService.open({
          message: worklogItem.issueKey.concat(' - Updated comment'),
          duration: 2000,
          action: 'Close'
        });
      }, e => {
        this.nwbSnackbarService.open({
          message: worklogItem.issueKey.concat(' - Error updating comment'),
          duration: 5000,
          action: 'Close'
        });
      });
    }
  }

  expandAll() {

    if(this.hasExpanded) {
      this.selectedIssues = [];
      this.hasExpanded = false;
      return;
    }

    this.selectedIssues = [];
    this.timeReport.issueItems.forEach((issue) => {
      this.selectedIssues.push(issue.issueKey);
    });
    this.hasExpanded = true;
  }

  ngOnInit() {
  }

}
