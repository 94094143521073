import { Component, OnInit } from '@angular/core';
import { JiraUser } from '../core/models/jiraUser';
import { AppConfigService } from '../core/services/appConfig.service';
import { Location, formatDate } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { JiraService } from '../core/services/jira.service';
import { ActivatedRoute } from '@angular/router';
import { PersonReportFiltersService } from './person-report-filters.service';
import { PersonReportLoadingService } from '../person-report/person-report-loading.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-person-report-filters',
  templateUrl: './person-report-filters.component.html',
  styleUrls: ['./person-report-filters.component.scss']
})
export class PersonReportFiltersComponent implements OnInit {

  public showSave: boolean = false;
  public selectedJiraUsers: JiraUser[];
  public filtersUrl: string = '';
  public jiraUsers: JiraUser[];
  public jiraUserDropdownSettings: IDropdownSettings;
  public personReportLoading: boolean;
  public selectedDateFrom: Date;
  public selectedDateTo: Date;
  public dateForm: FormGroup;
  public hasLoaded: boolean;

  faSave = faSave;
  faGoogle = faGoogle;
  
  constructor(
    private appConfigService: AppConfigService,
    private location: Location,
    private jiraService: JiraService,
    private route: ActivatedRoute,
    private personReportFiltersService: PersonReportFiltersService,
    private personReportLoadingService: PersonReportLoadingService,
    private fb: FormBuilder) {

      this.jiraUsers = [];
      this.selectedJiraUsers = [];
      this.hasLoaded = false;

      const todaysDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-GB');
      this.dateForm = this.fb.group({
        startDate: [todaysDate],
        endDate: [todaysDate]
      });

      this.jiraService.getJiraUsers().subscribe(users => {
        this.jiraUsers = users;

        this.route.params.subscribe(params => {
          if (params['user']) {
            const usernames:Array<any> = params['user'].split(',');
            this.selectedJiraUsers = this.jiraUsers.filter((user) => usernames.indexOf(user.name) >= 0);
          }
          if(params['dateFrom']) {
            this.setStartDate(params['dateFrom']);
          }

          if(params['dateTo']) {
            this.setEndDate(params['dateTo']);
          }

          this.hasLoaded = true;
        });

        this.applyFilters();
        this.personReportFiltersService.sendJiraUsers(this.jiraUsers);

      });

      this.personReportLoadingService.getLoading().subscribe(l => {
        this.personReportLoading = l;
      });
  }

  applyFilters() {
    this.personReportFiltersService.sendFilters({
      jiraUsers: this.selectedJiraUsers,
      dateFrom: this.selectedDateFrom,
      dateTo: this.selectedDateTo
    });
  }

  toggleSaveFilters() {
  
    if(this.showSave) {
      this.showSave = false;
      return;
    }

    this.updateFiltersUrl();
    
    this.showSave = true;
  }

  closeSaveFilters() {
    this.showSave = false;
  }

  updateFiltersUrl() {
    let path = '/person-report';

    if(this.selectedJiraUsers !== undefined && this.selectedJiraUsers.length > 0)
    {
       path += ';user=' + encodeURIComponent(this.selectedJiraUsers.map(u => u.name).join(','));
    }

    if(this.selectedDateFrom !== undefined)
    {
      path += ';dateFrom=' + formatDate(this.selectedDateFrom, 'yyyy-MM-dd', 'en-GB');
    }

    if(this.selectedDateTo !== undefined)
    {
      path += ';dateTo=' + formatDate(this.selectedDateTo, 'yyyy-MM-dd', 'en-GB');
    }

    this.filtersUrl = this.appConfigService.baseUrl + path;
    this.location.go(path);
  }

  setStartDate(s: string) {
    this.selectedDateFrom = new Date(s);
    this.dateForm.patchValue({
      startDate: s
    });
  }

  setEndDate(s: string) {
    this.selectedDateTo = new Date(s);
    this.dateForm.patchValue({
      endDate: s
    });
  }

  dateValueChange(e: any) {
    this.selectedDateFrom = e.startDate;
    this.selectedDateTo = e.endDate;
    this.updateFiltersUrl();
  }

  sendExportFilters() {
    this.personReportFiltersService.sendExportFilters({
      jiraUsers: this.selectedJiraUsers,
      dateFrom: this.selectedDateFrom,
      dateTo: this.selectedDateTo
    });
  }

  ngOnInit(): void {
    this.jiraUserDropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      idField: 'name',
      textField: 'displayName',
      itemsShowLimit: 1
    }
  }

}
