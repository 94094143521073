import { Component, OnInit } from '@angular/core';
import { JiraUser } from '../core/models/jiraUser';
import { JiraService } from '../core/services/jira.service';
import { TimeFiltersService } from './time-filters.service';
import { ScoroClient } from '../core/models/scoroClient';
import { ScoroService } from '../core/services/scoro.service';
import { ScoroProject } from '../core/models/scoroProject';
import { HomeService } from '../home/home.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AppConfigService } from '../core/services/appConfig.service';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { TimeReportService } from '../core/services/timeReport.service';

@Component({
  selector: 'app-time-filters',
  templateUrl: './time-filters.component.html',
  styleUrls: ['./time-filters.component.scss']
})
export class TimeFiltersComponent implements OnInit {

  public hasLoaded: boolean;
  public homeLoading: boolean;
  public selectedJiraUsers: JiraUser[];
  public selectedScoroClients: ScoroClient[];
  public selectedBillingAttribute: string = '';
  public selectedDateFrom: Date;
  public selectedDateTo: Date;
  public selectedScoroProjects: ScoroProject[];
  public multiDropdownSettings: IDropdownSettings;
  public singleDropdownSettings: IDropdownSettings;
  public jiraUserDropdownSettings: IDropdownSettings;

  public jiraUsers: JiraUser[];
  public scoroClients: ScoroClient[];
  public scoroProjects: ScoroProject[];

  public filtersUrl: string = '';
  public showSave: boolean = false;

  public dateForm: FormGroup;

  faSave = faSave;
  faGoogle = faGoogle;
  faFileExport = faFileExport;

  constructor(
    private jiraService: JiraService, 
    public timeFiltersService: TimeFiltersService,
    private scoroService: ScoroService,
    private appConfigService: AppConfigService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private homeService: HomeService,
    private location: Location,
    private timeReportService: TimeReportService) { 

      this.scoroProjects = [];
      this.scoroClients = [];
      this.jiraUsers = [];
      this.homeLoading = true;
      this.filtersUrl = appConfigService.baseUrl;

      const todaysDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-GB');
      this.dateForm = this.fb.group({
        startDate: [todaysDate],
        endDate: [todaysDate]
      });

      forkJoin(
        this.jiraService.getJiraUsers(),
        this.scoroService.getScoroClients()
      ).subscribe(([users, clients]) => {
        this.jiraUsers = users;
        //debugger;
        this.scoroClients = clients;
        this.hasLoaded = true;
        this.timeFiltersService.sendJiraUsers(this.jiraUsers);

        this.route.params.subscribe(params => {

          if (params['user']) {
            const usernames:Array<any> = params['user'].split(',');
            this.selectedJiraUsers = this.jiraUsers.filter((user) => usernames.indexOf(user.name) >= 0);
          }

          if(params['bill']) {
            this.selectedBillingAttribute = params['bill'];
          }

          if(params['dateFrom']) {
            this.setStartDate(params['dateFrom']);
          }

          if(params['dateTo']) {
            this.setEndDate(params['dateTo']);
          }

          
          if(params['client']) {
            const clientId:number = parseInt(params['client']);
            this.selectedScoroClients = this.scoroClients.filter((client) => client.value === clientId);

            this.scoroService.getScoroProjects(clientId.toString()).subscribe(result => {
              this.scoroProjects = result;
              
              if( params['projects']) {
                const projectIds:Array<number> = params['projects'].split(',').map((s) => parseInt(s));
                this.selectedScoroProjects = this.scoroProjects.filter((p) => projectIds.indexOf(parseInt(p.value)) >= 0);
              }

              this.applyFilters();
            });
          }

        });

        const snapshotParams = this.route.snapshot.params;

        if(snapshotParams['client'] === undefined) {
          this.applyFilters();
        }
        
      });

      this.homeService.getLoading().subscribe(l => {
        this.homeLoading = l;
      });
  }

  applyFilters() {
    this.timeFiltersService.sendFilters({
      jiraUsers: this.selectedJiraUsers,
      scoroClient: this.selectedScoroClients !== undefined && this.selectedScoroClients.length > 0 ? this.selectedScoroClients[0].value : undefined,
      scoroProjects: this.selectedScoroProjects,
      billingAttribute: this.selectedBillingAttribute,
      dateFrom: this.selectedDateFrom,
      dateTo: this.selectedDateTo
    });
  }

  dateValueChange(e: any) {
    this.selectedDateFrom = e.startDate;
    this.selectedDateTo = e.endDate;
    this.updateFiltersUrl();
  }

  scoroClientChange(item) {
    this.scoroService.getScoroProjects(item.value).subscribe(result => {
      this.scoroProjects = result;
      this.updateFiltersUrl();
    });
  }

  scoroClientDeSelect(item) {
    if(this.selectedScoroClients === undefined || this.selectedScoroClients.length === 0) {
      this.scoroProjects = [];
      this.selectedScoroProjects = [];
      this.updateFiltersUrl();
    }
  }

  setStartDate(s: string) {
    this.selectedDateFrom = new Date(s);
    this.dateForm.patchValue({
      startDate: s
    });
  }

  setEndDate(s: string) {
    this.selectedDateTo = new Date(s);
    this.dateForm.patchValue({
      endDate: s
    });
  }

  toggleSaveFilters() {
  
    if(this.showSave) {
      this.showSave = false;
      return;
    }

    this.updateFiltersUrl();
    
    this.showSave = true;
  }

  closeSaveFilters() {
    this.showSave = false;
  }

  updateFiltersUrl() {
    let path = '/home';

    if(this.selectedJiraUsers !== undefined && this.selectedJiraUsers.length > 0)
    {
      path += ';user=' + encodeURIComponent(this.selectedJiraUsers.map(u => u.name).join(','));
    }

    if(this.selectedScoroClients !== undefined && this.selectedScoroClients.length > 0)
    {
      path += ';client=' + this.selectedScoroClients[0].value;
    }

    if(this.selectedScoroProjects !== undefined && this.selectedScoroProjects.length > 0)
    {
      path += ';projects=' + encodeURIComponent(this.selectedScoroProjects.map(u => u.value).join(','));
    }

    if(this.selectedBillingAttribute !== undefined && this.selectedBillingAttribute.trim().length > 0)
    {
      path += ';bill=' + this.selectedBillingAttribute;
    }

    if(this.selectedDateFrom !== undefined)
    {
      path += ';dateFrom=' + formatDate(this.selectedDateFrom, 'yyyy-MM-dd', 'en-GB');
    }

    if(this.selectedDateTo !== undefined)
    {
      path += ';dateTo=' + formatDate(this.selectedDateTo, 'yyyy-MM-dd', 'en-GB');
    }

    this.filtersUrl = this.appConfigService.baseUrl + path;
    this.location.go(path);
  }

  sendExportFilters() {
    this.timeFiltersService.sendExportFilters({
      jiraUsers: this.selectedJiraUsers,
      scoroClient: this.selectedScoroClients !== undefined && this.selectedScoroClients.length > 0 ? this.selectedScoroClients[0].value : undefined,
      scoroProjects: this.selectedScoroProjects,
      billingAttribute: this.selectedBillingAttribute,
      dateFrom: this.selectedDateFrom,
      dateTo: this.selectedDateTo
    });
  }

  exportCSV() {
    window.open(this.timeReportService.getExportCSVUrl(
      this.selectedJiraUsers,
      this.selectedScoroProjects,
      this.selectedScoroClients !== undefined && this.selectedScoroClients.length > 0 ? this.selectedScoroClients[0].value : undefined,
      this.selectedBillingAttribute,
      this.selectedDateFrom,
      this.selectedDateTo
    ));
  }

  ngOnInit() {

    this.singleDropdownSettings = {
      singleSelection: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      idField: 'value',
      textField: 'label'
    }

    this.multiDropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection: false
    }

    this.jiraUserDropdownSettings = {...this.singleDropdownSettings,...{idField: 'name', textField: 'displayName',  itemsShowLimit: 1, singleSelection: false}};
  }

}
