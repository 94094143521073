<div class="panel">
    <div class="panel-heading">
        <button class="button is-small is-pulled-right" style="margin-left: 9px;" (click)="exportCSV()" [disabled]="homeLoading">
            <span class="icon is-small">
                <fa-icon [icon]="faFileExport"></fa-icon>
            </span>
            <span>Export CSV</span>
        </button>
        <button class="button is-small is-pulled-right is-success" (click)="sendExportFilters()" [disabled]="homeLoading">
            <span class="icon is-small">
                <fa-icon [icon]="faGoogle"></fa-icon>
            </span>
            <span>Export to Google Docs</span>
        </button>
        <div class="dropdown is-right is-pulled-right save-dropdown" [class.is-active]="showSave">
            <div class="dropdown-trigger">
                <button class="button is-small is-info" aria-haspopup="true" aria-controls="dropdown-menu" (click)="toggleSaveFilters()" [disabled]="homeLoading">
                <span class="icon is-small">
                    <fa-icon [icon]="faSave"></fa-icon>
                </span>
                <span *ngIf="!showSave">Save</span>
                <span *ngIf="showSave">Close</span>
                </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                    <div class="dropdown-item">
                        <div class="content">
                        <p><input class="input save-input" [(ngModel)]="filtersUrl" (click)="$event.target.select()"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        <span>Filters</span>
    </div>
    <div class="panel-block">
        <div class="content" *ngIf="!hasLoaded">
            <nwb-spinner [color]="'#bbbbbb'"></nwb-spinner>
        </div>
        <div class="columns" style="width: 100%;" [class.is-hidden]="!hasLoaded">
            <div class="column is-2">
                <div class="field">
                    <label class="label">Jira User</label>
                    <div class="control is-expanded">
                       <ng-multiselect-dropdown
                            [placeholder]="'All'"
                            [data]="jiraUsers"
                            [settings]="jiraUserDropdownSettings"
                            [(ngModel)]="selectedJiraUsers"
                            (onSelect)="updateFiltersUrl()"
                            (onDeSelect)="updateFiltersUrl()">
                        </ng-multiselect-dropdown>   
                    </div>
                </div>
            </div>
            <div class="column is-2">
                <div class="field">
                    <label class="label">Scoro Client</label>
                    <div class="control is-expanded">
                        <ng-multiselect-dropdown
                            [placeholder]="'All'"
                            [data]="scoroClients"
                            [settings]="singleDropdownSettings"
                            (onSelect)="scoroClientChange($event)"
                            (onDeSelect)="scoroClientDeSelect($event)"
                            [(ngModel)]="selectedScoroClients">
                        </ng-multiselect-dropdown> 
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field">
                    <label class="label">Scoro Project</label>
                    <div class="control is-expanded">
                        <ng-multiselect-dropdown
                            [placeholder]="'All'"
                            [data]="scoroProjects"
                            [disabled]="selectedScoroClients === undefined || selectedScoroClients.length === 0 || scoroProjects === undefined || scoroProjects.length === 0"
                            [settings]="multiDropdownSettings"
                            [(ngModel)]="selectedScoroProjects"
                            (onSelect)="updateFiltersUrl()"
                            (onDeSelect)="updateFiltersUrl()">
                        </ng-multiselect-dropdown> 
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label class="label">Billable</label>
                    <div class="control is-expanded">
                        <div class="select is-fullwidth">
                            <select [(ngModel)]="selectedBillingAttribute" (change)="updateFiltersUrl()">
                                <option value="">All</option>
                                <option value="false">Billable</option>
                                <option value="true">Non Billable</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <div class="field">
                    <label class="label">Date</label>
                    <div class="control is-expanded">
                        <form [formGroup]="dateForm">
                            <nwb-date-picker #date (change)="dateValueChange($event)" [options]="{'allowSameDayRange':true,'dateFormat':'DD/MM/YYYY'}">
                                <input [nwbDatepickerStart]="date" type="date" name="startDate" formControlName="startDate" (change)="updateFiltersUrl()" />
                                <input [nwbDatepickerEnd]="date" type="date" name="endDate"  formControlName="endDate" (change)="updateFiltersUrl()" />
                            </nwb-date-picker>
                        </form>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label class="label">&nbsp;</label>
                    <div class="control">
                        <button class="button is-primary" (click)="applyFilters()" [class.is-loading]="homeLoading" [disabled]="homeLoading">Apply</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>