import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TimeFilters } from '../core/models/timeFilters';
import { ProjectFilters } from '../core/models/projectFilters';
import { ExportFilters } from '../core/models/exportFilters';
import { ExportableFiltersService } from '../core/interfaces/exportableFiltersService.interface';

@Injectable({ providedIn: 'root' })
export class ProjectFiltersService implements ExportableFiltersService {
    private subject = new Subject<any>();
    private exportSubject = new Subject<ExportFilters>();

    sendFilters(filters: ProjectFilters) {
        this.subject.next(filters);
    }

    getFilters(): Observable<TimeFilters> {
        return this.subject.asObservable();
    }

    sendExportFilters(filters: ExportFilters) {
        this.exportSubject.next(filters);
    }

    getExportFilters():Observable<ExportFilters> {
        return this.exportSubject.asObservable();
    }
}