import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hourlyRateToDay'
  })
  export class HourlyRateToDayPipe implements PipeTransform {
      transform(value: number): number {
         const dayRate: number = Math.round(value * 7.5);
         return dayRate;
      }
  }