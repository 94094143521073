import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Loading } from '../interfaces/loading.interface';

@Injectable({ providedIn: 'root' })
export class LoadingService implements Loading {
    protected loadingSubject = new Subject<boolean>();

    sendLoading(loading: boolean) {
        this.loadingSubject.next(loading);
    }

    getLoading(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }
}