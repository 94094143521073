import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScoroProject } from '../models/scoroProject';
import { ProjectReport } from '../models/projectReport';
import { DocExport } from '../models/docExport';
import { BaseApiService } from './baseApiService';
import { JiraUser } from '../models/jiraUser';
import { ExportApi } from '../interfaces/exportApi.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjectReportApiService extends BaseApiService implements ExportApi {

  public getProjectReport(scoroProjects?: ScoroProject[], scoroClient?: number, dateFrom?: Date, dateTo?: Date):Observable<ProjectReport> {
    const params = this.httpParams(null, scoroProjects, scoroClient, null, dateFrom, dateTo);
    return this.httpClient.get<ProjectReport>(this.config.apiBaseUrl + '/api/projectreport', {params});
  }

  public getExport(
    jiraUsers: JiraUser[], 
    scoroProjects: ScoroProject[], 
    scoroClient: number, 
    billingType: string, 
    dateFrom: Date, 
    dateTo: Date,
    emailAddress: string):Observable<DocExport> {
    let params = this.httpParams(jiraUsers, scoroProjects, scoroClient, billingType, dateFrom, dateTo);
    params = params.set('emailAddress', emailAddress);
    return this.httpClient.get<DocExport>(this.config.apiBaseUrl + '/api/projectreport/export', {params});
  }

}
