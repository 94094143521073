import { Component, OnInit } from '@angular/core';
import { ProjectsReportService } from './projects-report.service';
import { ProjectFiltersService } from '../project-filters/project-filters.service';
import { Title } from '@angular/platform-browser';
import { ProjectReportApiService } from '../core/services/projectReportApi.service';
import { ProjectReport } from '../core/models/projectReport';
import { Project } from '../core/models/project';
import { CurrencyPipe, formatDate } from '@angular/common';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { TimeFilters } from '../core/models/timeFilters';

@Component({
  selector: 'app-projects-report',
  templateUrl: './projects-report.component.html',
  styleUrls: ['./projects-report.component.scss']
})
export class ProjectsReportComponent implements OnInit {

  public hasLoaded: boolean;
  public projectReport: ProjectReport;
  public selectedProjects = [];
  public hasExpanded: boolean = false;
  public visibleQuote: number = 0;
  public filters: TimeFilters;

  faInfoCircle = faInfoCircle;

  constructor(public projectsReportService: ProjectsReportService, 
    public projectFiltersService: ProjectFiltersService,
    private titleService: Title,
    public projectReportApiService: ProjectReportApiService,
    private currencyPipe: CurrencyPipe) { 

    this.titleService.setTitle('Project Report - MTRCP');
    this.projectsReportService.sendLoading(true);
    this.hasLoaded = false;
    this.projectReport = new ProjectReport();
    this.projectReport.projects = [];
    this.projectReport.count = 0;

    this.projectFiltersService.getFilters().subscribe(filters => {
      this.hasLoaded = false;
      this.projectsReportService.sendLoading(true);
      this.filters = filters;
      this.projectReportApiService.getProjectReport(
        filters.scoroProjects, 
        filters.scoroClient, 
        filters.dateFrom, 
        filters.dateTo
      ).subscribe(result => {
        this.projectReport = result;
        this.hasLoaded = true;
        this.projectsReportService.sendLoading(false);
      });
    });

  }

  ngOnInit() {
   
  }

  filterByPositiveId(project) {
    return project.projectLines.filter(x => x.id > 0);
  }

  selectProject(project: Project) {
    const existingIndex = this.selectedProjects.indexOf(project.scoroProjectId);
    if(existingIndex >= 0) {
      this.selectedProjects.splice(existingIndex, 1);
    } else {
      this.selectedProjects.push(project.scoroProjectId);
    }

    this.hasExpanded = this.selectedProjects.length > 0;
  }

  expandAll() {

    if(this.hasExpanded) {
      this.selectedProjects = [];
      this.hasExpanded = false;
      return;
    }

    this.selectedProjects = [];
    this.projectReport.projects.forEach((p) => {
      this.selectedProjects.push(p.scoroProjectId);
    });
    this.hasExpanded = true;
  }

  scoroQuotesText(project: Project) {

    if(project.scoroQuotes === undefined || project.scoroQuotes.length === 0) {
      return 'No quotes';
    }

    let text = '';
    project.scoroQuotes.forEach((v, i) => {

      text += 'Quote ' + (i + 1) + ': ' + this.currencyPipe.transform(v.sum, 'GBP') + (i + 1 < project.scoroQuotes.length ? ', ' : '');
    });
    return text;

  }

  showQuote(project: Project) {
    this.visibleQuote = project.scoroProjectId;
  }

  hideQuote() {
    this.visibleQuote = 0;
  }

  getTimeReportParams(project: Project) {

    let params: any = {};

    if(project.scoroClientId > 0)
    {
      params.client = project.scoroClientId;
    }

    params.projects = project.scoroProjectId;

    if(this.filters !== undefined && this.filters.dateFrom !== undefined)
    {
      params.dateFrom = formatDate(this.filters.dateFrom, 'yyyy-MM-dd', 'en-GB');
    }

    if(this.filters !== undefined && this.filters.dateTo !== undefined)
    {
      params.dateTo = formatDate(this.filters.dateTo, 'yyyy-MM-dd', 'en-GB');
    }

    return params;
  }
}