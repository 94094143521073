<div class="modal" [class.is-active]="isOpen">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Export to Google Docs</p>
        <button class="delete" aria-label="close" (click)="close()"></button>
      </header>
      <section class="modal-card-body">
        <input #email class="input is-medium" [(ngModel)]="emailAddress" type="text" placeholder="Email address (for permissions)" [class.is-hidden]="hasEmail"> 
        <div class="content" *ngIf="hasEmail && isLoading">
            <p>Exporting... please wait this can take several minutes...</p>
            <p style="margin-top: 40px;" *ngIf="isLoading">
                <nwb-spinner [color]="'#bbbbbb'"></nwb-spinner>
            </p>
        </div>
        <div class="content" *ngIf="hasEmail && !isLoading">
            <div *ngIf="!hasError">
                <h4>Result Message</h4>
                <p>{{docExport.resultMessage}}</p>
                <h4>Document Link</h4>
                <p><a href="{{docExport.docLink}}" target="_blank">{{docExport.docLink}}</a></p>
            </div>
            <div *ngIf="hasError">
                <div class="notification is-danger">
                    Unable to export to Google docs. See console to debug!
                </div>
            </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button *ngIf="!hasEmail" class="button" (click)="setEmail()" [disabled]="emailAddress.length === 0 || emailAddress.indexOf('@') <= 0">Export</button>
        <button *ngIf="hasEmail" class="button" (click)="close()" [class.is-loading]="isLoading" [disabled]="isLoading">Finish</button>
      </footer>
    </div>
  </div>