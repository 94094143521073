import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TimeFilters } from '../core/models/timeFilters';
import { JiraUser } from '../core/models/jiraUser';
import { ExportableFiltersService } from '../core/interfaces/exportableFiltersService.interface';
import { ExportFilters } from '../core/models/exportFilters';

@Injectable({ providedIn: 'root' })
export class TimeFiltersService implements ExportableFiltersService {
    private subject = new Subject<any>();
    private jiraUsersSubject = new Subject<JiraUser[]>();
    private exportSubject = new Subject<ExportFilters>();

    sendFilters(timeFilters: TimeFilters) {
        this.subject.next(timeFilters);
    }

    sendJiraUsers(jiraUsers: JiraUser[]) {
        this.jiraUsersSubject.next(jiraUsers);
    }

    getFilters(): Observable<TimeFilters> {
        return this.subject.asObservable();
    }

    getJiraUsers(): Observable<JiraUser[]> {
        return this.jiraUsersSubject.asObservable();
    }

    sendExportFilters(filters: ExportFilters) {
        this.exportSubject.next(filters);
    }

    getExportFilters():Observable<ExportFilters> {
        return this.exportSubject.asObservable();
    }
}