import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appConfig.service';
import { Observable } from 'rxjs';
import { ScoroClient } from '../models/scoroClient';
import { ScoroProject } from '../models/scoroProject';

@Injectable({
  providedIn: 'root'
})
export class ScoroService {

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) { }

  public getScoroClients():Observable<ScoroClient[]> {
    return this.httpClient.get<ScoroClient[]>(this.config.apiBaseUrl + '/api/scoro/clients');
  }

  public getScoroProjects(clientId: string):Observable<ScoroProject[]> {
    return this.httpClient.get<ScoroProject[]>(this.config.apiBaseUrl + '/api/scoro/projects/' + clientId);
  }
}
