<nav class="navbar is-danger" role="navigation" aria-label="main navigation">
  <div class="container">
    <div class="navbar-brand">

      <a class="navbar-item" [routerLink]="['/']">
        <!-- <img src="/src/assets/images/logo.png"/>  -->
        <span class="titleStrong">Mentor Digital</span>&nbsp;Reporting
      </a>
    </div>
    <div class="navbar-menu">
      <div class="navbar-end">
        <a class="navbar-item" [routerLink]="['/home']" [routerLinkActive]="'is-active'">
          Time Report
        </a>
        <!--<a class="navbar-item" [routerLink]="['/projects-report']" [routerLinkActive]="'is-active'">
          Projects Report
        </a>
        <a class="navbar-item" [routerLink]="['/person-report']" [routerLinkActive]="'is-active'">
          Person Report-->
        <!-- </a> -->
      </div>
    </div>
  </div>
</nav>