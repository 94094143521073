import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ExportableFiltersService } from '../core/interfaces/exportableFiltersService.interface';
import { ExportApi } from '../core/interfaces/exportApi.interface';
import { DocExport } from '../core/models/docExport';
import { ExportFilters } from '../core/models/exportFilters';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss']
})
export class ExportModalComponent implements OnInit {

  @Input() exportableFiltersService: ExportableFiltersService;
  @Input() exportApiService: ExportApi;
  @ViewChild('email') emailElement: ElementRef;

  public isOpen: boolean = false;
  public isLoading: boolean = false;

  public docExport: DocExport;
  public hasError: boolean = false;
  public hasEmail: boolean = false;
  public emailAddress: string = '';
  public filters: ExportFilters;

  constructor() { 
    this.docExport = new DocExport();
  }

  ngOnInit(): void {
    const storedEmailAddress = localStorage.getItem('exportEmailAddress');
    if(storedEmailAddress !== null && storedEmailAddress.indexOf('@') > 0) {
      this.emailAddress = storedEmailAddress;
    }
    this.exportableFiltersService.getExportFilters().subscribe(filters => {
      this.filters = filters;
      this.isOpen = true; 
      const elem = this.emailElement.nativeElement;
      setTimeout(function() {
        elem.focus();
      }, 0);
    });
  }

  close() {
    
    if(this.isLoading) {
      return;
    }

    this.isOpen = false;
    this.isLoading = false;
    this.hasError = false;
    this.hasEmail = false;
  }

  setEmail() {
    this.hasEmail = true;
    this.isLoading = true;
    this.hasError = false;  
    localStorage.setItem('exportEmailAddress', this.emailAddress); 
    this.exportApiService.getExport(
      this.filters.jiraUsers, 
      this.filters.scoroProjects, 
      this.filters.scoroClient, 
      this.filters.billingAttribute, 
      this.filters.dateFrom, 
      this.filters.dateTo,
      this.emailAddress
    ).subscribe(result => {
      this.isLoading = false;
      this.docExport = result;
    }, err => {
      this.hasError = true;
      this.isLoading = false;
    });
  }

}
