<section class="section">
    <app-time-filters></app-time-filters>
    <hr>
    <div class="panel">
        <p class="panel-heading">Time report <span class="smallTitle">Issues are cached, if title or Scoro Project has changed on a ticket <a href="https://scoroapi.mentordigital.co.uk/api/jira/clearcache" target="_blank">clear the cache.</a></span>
            <span class="is-pulled-right has-text-info is-size-6 is-clickable expand-all" *ngIf="hasLoaded" (click)="expandAll()">
                <span *ngIf="!hasExpanded">Expand All</span>
                <span *ngIf="hasExpanded">Collapse All</span>
            </span>
            <span class="is-pulled-right" *ngIf="hasLoaded">Total Hours: {{formatHours(timeReport.totalHours)}}</span>
        </p>
        <div class="panel-block">
            <div class="content" *ngIf="!hasLoaded">
                <nwb-spinner [color]="'#bbbbbb'"></nwb-spinner>
            </div>
            <div class="content" *ngIf="hasLoaded && timeReport !== undefined && timeReport.issueItems.length === 0">
                <p>No issues to show for the selected filters</p>
            </div>
            <table class="table is-fullwidth is-hoverable" *ngIf="hasLoaded && timeReport !== undefined && timeReport.issueItems.length > 0">
                <tbody>
                    <ng-container *ngFor="let issue of timeReport.issueItems">
                        <tr class="is-clickable" (click)="selectIssue(issue)" [class.is-selected]="selectedIssues.indexOf(issue.issueKey) >= 0">
                            <td>
                                <strong>
                                    <a href="https://mentordigital.atlassian.net/browse/{{issue.issueKey}}"
                                    nwbToolTip="Click to view ticket on Jira" class="has-text-black" target="_blank">{{issue.issueKey}}</a></strong>
                            </td>
                            <td>
                                <ng-container *ngFor="let issueLink of issue.issueLinks;let isLast=last">
                                    <strong>
                                        <a href="https://mentordigital.atlassian.net/browse/{{issueLink.key}}"
                                        nwbToolTip="Click to view ticket on Jira" class="has-text-black" target="_blank">{{issueLink.key}}</a> 
                                        {{isLast ? '' : ' | '}}</strong>
                                </ng-container>
                            </td>
                            <td>
                                <strong>{{issue.issueSummary}}</strong>
                               <!-- <a href="https://jira.atlashost.eu/mentordigital/browse/{{issue.issueKey}}" class="has-text-info is-size-7" nwbToolTip="Click to view ticket on Jira" target="_blank">
                                    <fa-icon [icon]="faExternalLinkAlt"></fa-icon></a> -->
                            </td>
                            <td colspan="2">{{issue.scoroProject}}</td>
                            <td colspan="3">Total: {{formatHours(issue.totalHours)}} hrs</td>
                        </tr>
                        <tr *ngFor="let worklogItem of issue.worklogItems" [class.is-hidden]="selectedIssues.indexOf(issue.issueKey) === -1">
                            <td colspan="2">{{worklogItem.workDateUkFormat}}</td>
                            <td colspan="2" nwbToolTip="Click to edit comment - Ctrl+Enter to save" contenteditable
                            (keydown)="commentKeydown($event)"
                            (keyup)="commentKeyup($event, $event.target.innerHTML, worklogItem)">{{worklogItem.workDescription}}</td>
                            <td>{{worklogItem.username}}</td>
                            <td>{{formatHours(worklogItem.hours)}} hrs</td>
                            <td>
                                <div class="pretty p-switch">
                                    <input type="checkbox" [checked]="isBillable(worklogItem)" (click)="changeBillable(worklogItem)" nwbToolTip="Toggle billable status" />
                                    <div class="state p-success">
                                        <label>Bill</label>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="pretty p-switch">
                                    <input type="checkbox" [checked]="isOOS(worklogItem)" (click)="changeOOS(worklogItem)" nwbToolTip="Toggle OOS" />
                                    <div class="state p-success">
                                        <label>OOS</label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                     
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal" [class.is-active]="isShowSaveWorklog">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Update Worklog</p>
              <button class="delete" aria-label="close" (click)="hideSaveWorklog()"></button>
            </header>
            <section class="modal-card-body">
              <input type="hidden" ([ngModel])="currentWorklogId">
              <div class="content">
                <div class="field">
                    <label class="label">Issue</label>
                    <p>{{currentIssueKey}} - {{currentIssueSummary}}</p>
                </div>
                <div class="field">
                    <label class="label">User</label>
                    <p>{{currentWorklogUsername}}</p>
                </div>
                <div class="field">
                    <label class="label">Billing Type</label>
                    <label class="checkbox">
                        <input type="checkbox" [checked]="currentWorklogBillable" [(ngModel)]="currentWorklogBillable">
                        Billable?
                    </label>
                </div>
                <div class="field">
                    <label class="label">Comment</label>
                    <div class="control">
                        <textarea class="textarea" 
                        placeholder="Enter a comment for this worklog item" [(ngModel)]="currentWorklogComment"></textarea>
                    </div>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" (click)="saveWorklog()" [class.is-loading]="isSavingWorklog" [disabled]="isSavingWorklog">Save</button>
              <button class="button" (click)="hideSaveWorklog()">Cancel</button>
              <span class="is-pulled-right has-text-danger" [class.is-hidden]="!worklogError"><strong>Error saving worklog. Maybe permissions.</strong></span>
            </footer>
          </div>
    </div>
</section>
<app-export-modal [(exportableFiltersService)]="timeFiltersService" [(exportApiService)]="timeReportService"></app-export-modal>