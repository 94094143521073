import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TimeFiltersComponent } from './time-filters/time-filters.component';
import { AppConfigService } from './core/services/appConfig.service';

import {NwbAllModule} from '@wizishop/ng-wizi-bulma';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProjectsReportComponent } from './projects-report/projects-report.component';
import { ProjectFiltersComponent } from './project-filters/project-filters.component';
import { SecondsToHoursPipe } from './core/pipes/secondsToHours.pipe';
import { HourlyRateToDayPipe } from './core/pipes/hourlyRateToDay.pipe';
import { CurrencyPipe } from '@angular/common';
import { PersonReportFiltersComponent } from './person-report-filters/person-report-filters.component';
import { PersonReportComponent } from './person-report/person-report.component';
import { ExportModalComponent } from './export-modal/export-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    TimeFiltersComponent,
    ProjectsReportComponent,
    ProjectFiltersComponent,
    SecondsToHoursPipe,
    HourlyRateToDayPipe,
    PersonReportFiltersComponent,
    PersonReportComponent,
    ExportModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NwbAllModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          return appConfigService.loadAppConfig();
        };
      }
    },
    SecondsToHoursPipe,
    HourlyRateToDayPipe,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
